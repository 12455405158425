@font-face {
    font-family: 'mazar';
    src:  url('../fonts/mazar.eot?qxoqj');
    src:  url('../fonts/mazar.eot?qxoqj#iefix') format('embedded-opentype'),
    url('../fonts/mazar.ttf?qxoqj') format('truetype'),
    url('../fonts/mazar.woff?qxoqj') format('woff'),
    url('../fonts/mazar.svg?qxoqj#mazar') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="mazar-icon-"], [class*=" mazar-icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'mazar' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.mazar-icon-acces:before {
    content: "\e900";
}
.mazar-icon-accueil:before {
    content: "\e902";
}
.mazar-icon-aide:before {
    content: "\e902";
}
.mazar-icon-contacts:before {
    content: "\e903";
}
.mazar-icon-evenements:before {
    content: "\e906";
}
.mazar-icon-nouvelle_entreprise:before {
    content: "\e909";
}
.mazar-icon-parametre:before {
    content: "\e90a";
}
.mazar-icon-puce:before {
    content: "\e90b";
}
.mazar-icon-tel:before {
    content: "\e90d";
}
.mazar-icon-utilisateur:before {
    content: "\e90e";
}
.icon-sweden:before {
    content: "\ea0d";
}
.mazar-icon-receipt:before {
    content: "\e904";
}

.mazar-icon-service-removed:before {
    content: "\e923";
}

.mazar-icon-service-disabled:before {
    content: "\e922";
}

.mazar-icon-service-enabled:before {
    content: "\e921";
}