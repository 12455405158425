$body-bg: #EBEEF2;
$body-color: #fff;
$primary: #6F508D;
$secondary: #005794;
$btn-border-radius:    2px;
$btn-border-radius-lg: 2px;
$btn-border-radius-sm: 2px;
$btn-border-radius-sm: 2px;
$font-family-sans-serif: 'Roboto';

@import "../../../node_modules/bootstrap/scss/bootstrap";