@media (max-width: 890px) {
    .col-md-12.items-container {
        margin: 0 auto;
    }
}
@media (max-width: 670px) {
    #wrapper.toggled {
        padding-left: 0;
    }
}
@media (max-width: 587px) {
    .header-right-icons .notification-icon {
        margin: 0 10px;
    }
}
@media (max-width: 576px) {
    .notifications-modal .notification-btn {
        margin-top: 15px;
    }
    .flag-container ul[data-v-00867bb8]{
        width: 300px;
    }
}
@media (max-width: 526px) {
    .header-right-icons .notification-icon {
        margin: 0 10px;
    }
    .content-container .fixed-top.navbar {
        height: auto;
        flex-direction: column;
    }
    .content-container {
        padding-top: 175px;
    }
    .contact-container {
        min-height: 480px;
    }
}
@media (max-width: 500px) {
    .notification-modal-responsive .v--modal-box {
        top: 0 !important;
        left: 0 !important;
        width: 100% !important;
        height: auto !important;
    }
}
@media screen and (max-width: 900px) and (orientation: landscape) {
    .notification-modal-responsive .v--modal-box {
        top: 0 !important;
        left: 0 !important;
        width: 100% !important;
        height: auto !important;
    }
    .notification-content-bloc {
        height: 105px;
    }
}
@media (max-width: 360px) {
    .guide-online span, .contact-number, .request-contact span, .online-contact span{
        font-size: 14px;
    }
}
@media (max-width: 380px) {
    .help-modal-responsive .v--modal-box {
        top: 0 !important;
        left: 0 !important;
        width: 100% !important;
        height: auto !important;
    }

    .homepage-container .container-fluid {
        width: 250px;
    }
}
@media (max-width: 450px) {
    .homepage-container .container-fluid {
        width: 315px;
    }
}
@media (max-width: 450px) {
    .user-modal-responsive .v--modal-box {
        left:0 !important;
        width: 100% !important;
        height: auto !important;
    }
}
@media (max-width: 600px) {
    .edit-image-modal-responsive .v--modal-box, .upload-image-modal-responsive .v--modal-box{
        left: 0 !important;
        width: 100% !important;
        height: auto !important;
    }
}
@media (max-width: 650px) {
    .header-right-icons .notification-icon {
        margin: 0 10px;
    }

    .content-container .fixed-top.navbar {
        height: auto;
        flex-direction: column;
    }

    .content-container {
        padding-top: 175px;
    }

    .contact-container {
        min-height: 480px;
    }
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
        position: relative;
        width: 100%;
         padding-right: 0;
        padding-left: 15px;
    }
}


